.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important; /* Set the arrow color to black */
}



@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}
@media (max-width: 426px) {
  .swiper-slide {
    width: 17.7rem !important; /* Adjust width for Mobile S */
    height: auto !important; /* Adjust height for Mobile S */
  }

  .swiper-slide img {
    width: 100% !important;  /* Ensure image takes up full width */
    height: 100% !important;  /* Maintain aspect ratio of the image */
    object-fit: contain !important;  
  }
}
/* Add this to your index.css file */
.swiper-slide-box {
  position: relative;
  text-align: center;
}


