 
.text-area{
    border-radius: 10px;
    background-color: #c5c5c56b;
    color: #FFDA78;
    font-size: 20px;
    font-family: Quicksand;
    text-align: center;
}
 
.faq-accordian {
    width: 85%;
    background-color: #010313 !important;
    color: #fff !important;
    border: 2px solid #fff;
    border-radius: 40px !important;
    margin-bottom: 10px;
    font-family: auto;
  }
 
  .faq-more-less {
    margin-left: "80%";
    cursor: pointer;
    text-decoration: underline !important;
    color: #fff;
  }