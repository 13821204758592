.menu-item:hover {
    color: #fff;
    font-size: 16px;
    /* width:100%; */
    font-weight: 700;
}

.underline-text {
    text-decoration: underline;
}
/* .menu-item:hover .down-arrow {
    display: none;
} */

/* .up-arrow {
    display: none;
} */

/* .menu-item:hover .up-arrow{
    display: block;
} */


.dropdown-main-continer{
    position: relative;
}
.dropdown-main-continer:hover .dropdown {
    display: block;
    position: absolute;
    top: 98%;
    left: 0;
    background-color: #0A0A23; /* added for clarity */
    z-index: 1300;
    width: 12vw;
    
}
.dropdown{
    display: none;
 }

 .dropdown-main-continer-cat{
    position: relative;
}
.dropdown-main-continer-cat:hover .dropdown-cat {
    display: block;
    position: absolute;
    top: 98%;
    left: 0;
    background-color: #0A0A23; /* added for clarity */
    z-index: 1500;
    width: 70vw;
    
}
.dropdown-cat{
    display: none;
 }

 .nav-item:hover{
    background-color: #FFDA78;
    border-radius: 6;
    color:#000000;
    font-weight: 900;
    cursor: pointer;
 }