
.movie-name{
    font-size: 22px;
     color: #ffffff;
     font-weight: 700;
       
}
 
.buttons{
    background-color: #6e7287 !important;
    color:#FFDA78 !important;
    height:40px !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-weight: 600 !important;
}
 
 
.btn-icon{
    font-size: 14px !important;
 
}
 
.imdb{
    color: #FFDA78;
    font-size: 12px;
    font-weight: 700;
 
}
 
.year-duration{
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 00 !important;     
}
 
.divider {
    display: inline-block;
    vertical-align: middle; /* Aligns the divider with the text */
    height: 1em; /* Adjust height as needed */
    line-height: 1em; /* Ensure line height matches */
    font-size: 20px; /* Adjust size to match your typography */
    color: rgba(255, 255, 255, 0.9); /* Match the color of your typography */
  }

  .full-height-video {
    width: 100%;    
    object-fit: cover;
    display: block;
  }
  
 