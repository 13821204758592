
.not-found {
    font-family: Merriweather !important;
    font-weight: 700;
    font-size: 130px !important;
    color: #0A0A23;
    padding-top: 80px;
}
 
.error-txt {
    text-align: center;
    font-family: Nunito !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #000000;
}
 
.para-txt {
    text-align: center;
    font-weight: 600  !important;
    font-family: Nunito !important;
    font-size: 15px !important;
    padding-bottom: 5px;
}
 
.button-text {
    margin: auto !important;
    background-color: #0A0A23;
    cursor: pointer;
}
 
.page-info {
    background-color: #F9E79F;
}
 