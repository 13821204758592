.footer-logo{
    height: 70px;
    width: 70px;
}
 
.footer-facebook{
    height: 30px;
    width: 30px;
    cursor: pointer;
}
 
.footer-instagram{
    height: 26px;
    width: 26px;
    border-radius: 8px;
    cursor: pointer;
}
.footer-telegram{
    height: 34px;
    width: 34px;
    cursor: pointer;
}
 
.footer-twitter{
    height: 26px;
    width: 26px;
    cursor: pointer;
 
}
 
.footer-youtube{
    height: 28px;
    width: 28px;
    border-radius: 20px;
    cursor: pointer;
}
 
.store{
    width: 44%;
}
 
.whatsapp {
    height: 28px;
    width: 28px;
    border-radius: 20px;
    cursor: pointer;
}