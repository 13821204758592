.playerWrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
  }

.video-player{
  padding: 30px;
  height:89.8vh;
  background-color: #000;
  }
  .video-container{
    background: #000;
  }

   
  .playerWrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
 
  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
  }
 
  .video-player {
    padding: 30px;
    height: 90vh;
    background-color: #000;
  }
 
  .video-container {
    background: #000;
  }
 
  .buffering-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: transparent;
    padding: 20px;
    border-radius: 50%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
 