

  /* BlogHeader.css */

.blog-header {
    background-color: #f8f8f8;
    border-bottom: 1px solid #000;
    /* padding: 10px 0; */
    display: flex;
    /* justify-content: space-between;padding-left: 30px; padding-right: 30px; */
  }
  
  .logo-link {
    text-decoration: none;
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  button {
    color: #333;
    font-size: 16px;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #ddd;
    border-radius: 5px;
  }

  .divider {
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    align-self: center;
    color: #000;
}
 
.footer-youtube{
    height: 28px;
    width: 28px;
    border-radius: 20px;
}
 
.footer-twitter{
    height: 26px;
    width: 26px;
    color: #000;
}
 
.footer-facebook{
    height: 30px;
    width: 30px;
}
 
.footer-instagram{
    height: 26px;
    width: 26px;
    border-radius: 8px;
}

