.suggestedMovieContainer{
    padding: 6px;
    color: #D1D0cf;
    box-sizing: border-box;
    width: "200px !important";
    cursor:pointer;
    text-align: center;
    font-size: 10px;
    font-family: 'Qicksand';
    flex-wrap: wrap;
}
.suggestedMovie{
    background-color: #D3B463;
    border: 1.35px solid #ffda78;
    border-radius: 7px;
    z-index: 1000;
    max-height: 110px;
/* max-width: fit-content; */
}