@media screen and (max-width: 620px) {
    .small-devices-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .zindex {
    position: absolute;
    z-index: 5;
  }
  
  .bgcolor {
    background-color: "#000000";
    opacity: 0.5;
  }
  
  .icons:hover {
    transform: scale(1.5);
  }
  
  @media screen and (max-width: 392px) {
    .videoIcon {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .videoIcon {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 900px) {
    .videoIcon {
      font-size: 18px;
    }
  }
  
  @media screen and (min-width: 900px) {
    .videoIcon {
      font-size: 20px;
    }
  }
  
  .screen-rotation-icon {
    color: #ffda78;
  }
  
  .videoIcon{
      color: #ffda78;
  }

.logo-video-player{
  width: 60px;
  height: auto;
} 