.bg-image {
    background-image: url("https://bucketblob.iott.co.in/Icons/bgImage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.logo {
    width: 120px;
    height: fit-content;
    padding-bottom: 2%;
    padding-top: 2%;
}

.input-label {
    color: #FFDA78;
    font-size: 14px;
    font-weight: 400;
    font-family: "Quicksand";
    margin-bottom: 2%;
}

.terms-conditions{
    color: #FFDA78;
    font-size: 10px !important;
    font-weight: 400;
    font-family: "Quicksand";
    margin-left:4px;
}


.info{
    color: #fff;
    font-size: 10px !important;
    font-weight: 700;
    font-family: "Merriweather" !important;
  
}

.signup-form{
    width: 75%;
    cursor: pointer;
}

.terms-conditions-error{
    color:#FE0606;
    font-family: 'Quicksand';
    font-size:12px;
    text-align: center;

}

 