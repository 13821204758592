
.footer-logo{
    height: 150px !important;
    width: 120px !important;
}
 
@media (max-width: 320px) {
    .footer-logo {
      max-width: 80px; /* Adjust this size as needed */
      max-height: 80px;
    }
  }
 
.footer-facebook{
    height: 30px;
    width: 30px;
}
 
.footer-instagram{
    height: 26px;
    width: 26px;
    border-radius: 8px;
}
.footer-telegram{
    height: 34px;
    width: 34px;
}
 
.footer-twitter{
    height: 26px;
    width: 26px;
 
}
 
.footer-youtube{
    height: 28px;
    width: 28px;
    border-radius: 20px;
}
 
 
@media (max-width: 320px) {
    .footer-facebook {
        height: 21px !important;
        width: 21px !important;
    }
 
    .footer-instagram {
        height: 20px !important;
        width: 20px !important;
        border-radius: 6px;
    }
 
    .footer-telegram {
        height: 26px !important;
        width: 26px !important;
    }
 
    .footer-twitter {
        height: 20px !important;
        width: 20px !important;
    }
 
    .footer-youtube {
        height: 22px !important;
        width: 22px !important;
        border-radius: 18px;
    }
}
 
 
/* .store{
    width: 44%;
} */
 
/* Default sizes for larger screens */
.store-image {
    height: 40px !important;
    width: 111px !important;
  }
 
  /* Adjust sizes for small screens (320px) */
  @media (max-width: 320px) and (max-width: 475px) {
    .store-image {
      height: 20px !important;  /* Adjust height as needed */
      width: 150px !important;   /* Adjust width as needed */
    }
  }
 
  @media (min-width: 476px) {
    .store-image {
      height: 40px;
      width: 150px !important;
    }
  }
 
 
 
 
 