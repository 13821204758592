.main-container-wallet{
    background-color:#010113;
}
.selected-event{
    cursor: pointer;
}
 
.selected-event:hover .wallet-images{
    height:50%;
    width:50%;
}
 
.wallet-images{
    height:40%;
    width:40%;
    margin-bottom: 4%;
}
 
.icon {
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
    .icon {
        width: 30px; /* Adjust size for smaller screens */
        height: 30px; /* Adjust size for smaller screens */
    }
}

@media (max-width: 480px) {
    .icon {
        width: 20px; /* Further adjust size for mobile screens */
        height: 20px; /* Further adjust size for mobile screens */
    }
}

.enter-amount{
    height: 40px;
    /* width: 100%; */
    background-color: #000000;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 600;
    padding:10px;
    font-size: 14px;
}
 
.Mui-checked .MuiSvgIcon-root {
    color: black; /* Change this to the desired color */
}
 
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
    font-size: 20px;
    font-weight: 500;
}
 
.proceed-btn{
    /* width: 100%; */
    min-width: 80px;
    margin-top: 10px;
    padding:10px;
    background-color: #000000;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    font-size: 18px
}
 
.proceed-btn:hover{
    color: #000;
    font-weight: bold;
    cursor: pointer;
}
 
.status-img{
    width:20px;
    height:20px;
}
 
.css-1uyrbu0 {
    width: 50% !important;
}
 
 
.bill-card{
    width: 75% !important;
}



/* Base size for larger screens */
.responsive-icon {
    font-size: 50px;
  }
  
  /* Adjust size for smaller screens */
  @media (max-width: 1200px) {
    .responsive-icon {
      font-size: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-icon {
      font-size: 30px;
    }
  }
  
  @media (max-width: 480px) {
    .responsive-icon {
      font-size: 20px;
    }
  }
  