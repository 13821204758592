.send-otp{
    background-color: #FFDA78;
    padding: 4px 10px 4px 10px;
    border: none;
    height:26px;
    border-radius: 13px;
    font-family: "Merriweather";
    font-size: 12px;
    font-weight: 800;
 
}
 

.arrow-icon:hover {
    cursor: pointer;
    font-size: large;
}


.signIn-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
 
 