
.image-container {
    padding: 6px;
    
    box-sizing: border-box; /* Ensure padding is included in the width */
    width: 100%; /* Ensure each container takes up the full width */
    display: inline-block; /* Ensure each container stays inline */
    cursor:pointer;
     
}

.movie-contianer {
    height: 100%; /* Ensure the movie container takes up the full height */
    width: 100%; /* Ensure the movie container takes up the full width */
    background-color: #D3B463;
    border: 1.35px solid #ffda78;
    border-radius: 7px;
    z-index: 1000;

   

}

 
.slide-images {
    height: 100%; /* Ensure the image takes up the full height of its container */
    width: 100%; /* Ensure the image takes up the full width of its container */
    border-radius: 7px;
    
}

.card-movie-name{
    color: #000000;
    font-family: 'Quicksand';
    font-size: 12px;
    margin-bottom: 0px;
}

.movie-rating{
    color: #FFFFFF;
    font-family: 'Quicksand';
    font-size: 10px;
}

.movie-layer-contianer{
    background-color: #D3B463;
    border: 2px solid #ffda78;
   cursor: pointer;
     
}

.content-container{
    /* display: none; */
    /* display: block; */
   padding:20px;
    /* z-index: 1000; */

   

}

/* .image-container:hover .content-container{
    display: block;
    padding: 10px;

} */

.card-movie-duration{
    color: #000000;
    font-family: 'Quicksand';
    font-size: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.card-movie-desc{
    width: 100%;
    height:64px;
    /* border: 1px solid #fff; */
    font-size: 10px;
    font-family: 'Quicksand';
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show */
    -webkit-box-orient: vertical;
    
 
}

.card-button{
    background-color: #151829 !important;
    color:#FFDA78 !important;
     border-radius: 10px !important;
    text-transform: none !important;
    font-weight: 600 !important;
    font-size: 12px  !important;
}

.card-btn-icon{
    font-size: 10px !important;

}

.card-hover {
    transition: transform 0.3s ease;
}
 
.card-hover:hover {
    transform: scale(1.1);
}