.aboutsUsContainer {
  padding: 0px;
}
img.img-fluid.logo.aboutus {
  width: 280px;
  margin-right: -32rem !important;
}
h4.about-top-textt {
  margin-top: 13rem;
  font-size: xx-large;
  margin-right: 0rem;
}
.aboutus-content {
  background-color: #010113;
  padding: 10px;
}
.aboutus-content h5 {
  text-align: initial;
  font-family: inherit;
  padding-bottom: 10px;
}
.aboutus-content p {
  font-size: 22px;
  text-align: left;
  line-height: 36px;
  font-family: inherit;
}
.main-about {
  display: contents;
  margin-bottom: 35px;
}
/* .abouts-top {
    margin-bottom: 35px;
} */

.main-gridabout {
  margin-bottom: 30px !important;
}

@media (max-width: 768px) {
  h4.about-top-textt {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 30px;
  }
}
.about-content {
  display: contents;
}

.about-title {
  color: #ffda78;
}

p.aboutdescription {
  text-align: justify !important;
}
