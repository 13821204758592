.image-cont {
  width: 70px;
  height: 50px;
  overflow: hidden;
  border-bottom-right-radius: 12px !important;
 }

.short-cont {
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
 
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: fit-content;
  margin:8px;
  /* border-radius: 10px 0px 10px 0px; */
  border-top-left-radius: 12px !important;

}

.slick-prev::before,
.slick-next::before {

  display: none;

}


 
.image-cont:hover .short-cont {
  margin-top: 0px;
  transform: scale(1.3);
  
}

 
