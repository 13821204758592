#subscription-card{
    padding-top: 0px !important;

}
 .subscription-card{
    transition: box-shadow 0.3s, transform 0.3s;
  box-shadow: 0px 0px 0px 0px #FE9B06;

 }

 .subscription-card:hover {
    box-shadow: 0px 0px 0px 5px #FE9B06;
    transform: scale(1);
  }
.css-1ujs0xw-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgba(255, 218, 120, 1);
}