.iott-header-logo{
    width: 100px !important;
    height:50px !important;
}
@media screen and (max-width: 500px) {
    .iott-header-logo {
        width: 80px !important;
        height: 40px !important;
    }
}
.HeaderIcons {
    cursor: pointer;
    display:"flex";
    flex-direction:"column";
    justify-content:"center";
    align-items:"center";
}

.css-iniqjp-MuiSvgIcon-root {
    font-size: 2.5rem !important;
}
.drawer-iott{
    background-color: rgba(4, 2, 35, 1);
    color: rgba(255, 255, 255, 1);
    min-height: "auto";
}

.drawer-list-iott{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    text-align: center;
}

.drawer-icon{
    margin-right: 10px;
}