.mv-paid{
    background-color:  #FFDA78;
    text-align: center;
    font-size: 14px;
    /* font-weight: 700; */
   border-radius: 8px;
}
 
.cast-img{
    width: 100px;
    height: 100px;
    border-radius: 10px;
}
 
.first-dialog {
    height: auto;
    padding: 10%;
  }
 
  .first-dialog-Amount {
    color: black;
    font-weight: bold;
    font-size: 0.75em;
  }
 
  .first-dialog-text {
    color: black;
    /* font-weight: bold; */
    font-size: 0.55em;
    margin-top: 7%;
  }
 
  .first-dialog-buttons-row {
    height: 20px;
    width: 200px;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0px;
    margin: 0px;
    margin-top: 5%;
  }
 
  .first-dialog-buttons {
    background-color: #ffda78 !important;
    border-radius: 10px;
    font-weight: bold !important;
    padding: 1px 6px !important;
 
    color: #000;
    text-transform: unset !important;
  }
 
  .second-dialog {
    padding: auto;
    overflow-y: hidden;
    overflow-x: hidden !important;
  }
 
  .second-dialog-Amount {
    color: black;
    text-align: center;
    font-weight: bold;
  }
 
  .second-dialog-wallet {
    background-color: #ffda78;
    height: 130px;
    width: 200px;
    margin-top: 5%;
  }
 
  .second-dialog-wallet-row {
    height: 50px;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
  }
 
  .second-dialog-wallet-row-main-text {
    font-size: 0.6em;
    font-weight: bold;
    color: black;
  }
 
  .second-dialog-wallet-row-small-text {
    font-size: 0.4em;
    font-weight: bold;
    color: black;
  }
 
  .balance {
    margin: 2px;
    text-align: center;
  }
 
  .second-dialog-buttons-row {
    height: 20px;
    width: 200px;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 0px;
  }
 
  .second-dialog-buttons {
    background-color: #ffda78 !important;
    border-radius: 10px;
    font-weight: bold !important;
    padding: 1px 8px !important;
 
    color: #000;
    text-transform: unset !important;
  }
 
  .radioButton {
    height: 20px !important;
    width: 20px !important;
    background-color: white !important;
    border-radius: 25px !important;
  }
  .divider{
    color: #fff !important;
  }
 